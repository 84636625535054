/* eslint-disable max-classes-per-file */
import { AuthState } from '@okta/okta-auth-js';
import { Dispatch } from 'redux';
import { Action } from 'redux';
import { fetchLinkedResponses, fetchSearchSources, fetchSources, fetchQnAResponses } from './Operations';
import { Action as AnyAction } from '@reduxjs/toolkit';
import { ISourceRequestParams } from '../container/SourcesPage';
import { ILinkedResponseRequestParams } from '../container/DocumentResponse/SourcesDocumentPage';
import { IQnAResponseRequestParams } from '../container/DocumentResponse/QnATable/QnATable';
import { IStoreState } from "../../Model";

export enum SourcesActionTypes {
  FetchSourcesPending = "FETCH_SOURCES_PENDING",
  FetchSourcesSuccess = "FETCH_SOURCES_SUCCESS",
  FetchSourcesRejected = "FETCH_SOURCES_REJECTED",
  FetchLinkedResponsesPending = "FETCH_LINKED_RESPONSES_PENDING",
  FetchLinkedResponsesSuccess = "FETCH_LINKED_RESPONSES_SUCCESS",
  FetchLinkedResponsesRejected = "FETCH_LINKED_RESPONSES_REJECTED",
  FetchQnAPending = "FETCH_QNA_PENDING",
  FetchQnASuccess = "FETCH_QNA_SUCCESS",
  FetchQnARejected = "FETCH_QNA_REJECTED",
}

export class FetchSourcesPendingAction implements Action {
  public readonly type = SourcesActionTypes.FetchSourcesPending;
}

export class FetchSourcesSuccessAction implements Action {
  public readonly type = SourcesActionTypes.FetchSourcesSuccess;
  constructor(public payload: { sources: Api.ISourcesResponse, operationType: "list" | "search" }) { }
}

export class FetchSourcesFailedAction implements Action {
  public readonly type = SourcesActionTypes.FetchSourcesRejected;
  constructor(public payload: { error: Api.ISourcesErrorData }) { }
}

export class FetchLinkedResponsesPendingAction implements Action {
  public readonly type = SourcesActionTypes.FetchLinkedResponsesPending;
}

export class FetchLinkedResponsesSuccessAction implements Action {
  public readonly type = SourcesActionTypes.FetchLinkedResponsesSuccess;
  constructor(public payload: { linkedResponses: Api.ILinkedResponses }) { }
}

export class FetchLinkedResponsesFailedAction implements Action {
  public readonly type = SourcesActionTypes.FetchLinkedResponsesRejected;
  constructor(public payload: { error: Api.ISourcesErrorData }) { }
}

export class FetchQnAPendingAction implements Action {
  public readonly type = SourcesActionTypes.FetchQnAPending;
}

export class FetchQnASuccessAction implements Action {
  public readonly type = SourcesActionTypes.FetchQnASuccess;
  constructor(public payload: { qnaResponses: Api.IQnAResponses }) { }
}

export class FetchQnAFailedAction implements Action {
  public readonly type = SourcesActionTypes.FetchQnARejected;
  constructor(public payload: { error: Api.ISourcesErrorData }) { }
}

export type SourcesAction = FetchSourcesPendingAction
  | FetchSourcesSuccessAction
  | FetchSourcesFailedAction
  | FetchLinkedResponsesPendingAction
  | FetchLinkedResponsesSuccessAction
  | FetchLinkedResponsesFailedAction
  | FetchQnAPendingAction
  | FetchQnASuccessAction
  | FetchQnAFailedAction;


export const getSourcesThunk: (auth: AuthState, requestParams: ISourceRequestParams) => any = (auth, requestParams) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchSourcesPendingAction());
    fetchSources(auth, requestParams)
      .then((response: Api.ISourcesResponse) => {
        dispatch(new FetchSourcesSuccessAction({ sources: response, operationType: "list" }));
      })
      .catch((errorResponse: Api.ISourcesErrorData) => {
        dispatch(new FetchSourcesFailedAction({ error: errorResponse }));
      });
  }
}

export const getSearchSourcesThunk: (auth: AuthState, searchText: string, requestParams: ISourceRequestParams) => any = (auth, searchText, requestParams) => {
  return (dispatch: Dispatch<AnyAction>) => {
    dispatch(new FetchSourcesPendingAction());
    fetchSearchSources(auth, searchText, requestParams)
      .then((response: Api.ISourcesResponse) => {
        dispatch(new FetchSourcesSuccessAction({ sources: response, operationType: "search" }));
      })
      .catch((errorResponse: Api.ISourcesErrorData) => {
        dispatch(new FetchSourcesFailedAction({ error: errorResponse }));
      });
  }
}

export const getLinkedResponsesThunk: (auth: AuthState, requestParams: ILinkedResponseRequestParams, documentId: string) => any = (auth, requestParams, documentId) => {
  return (dispatch: Dispatch<AnyAction>, getState: () => IStoreState) => {
    const tenantName = getState().tenants.selectedTenant;
    dispatch(new FetchLinkedResponsesPendingAction());
    fetchLinkedResponses(auth, requestParams, documentId, tenantName)
      .then((response: Api.ILinkedResponses) => {
        dispatch(new FetchLinkedResponsesSuccessAction({ linkedResponses: response }));
      })
      .catch((errorResponse: Api.ISourcesErrorData) => {
        dispatch(new FetchLinkedResponsesFailedAction({ error: errorResponse }));
      });
  }
}

export const getQnAResponsesThunk: (auth: AuthState, requestParams: IQnAResponseRequestParams) => any = (auth, requestParams) => {
  return (dispatch: Dispatch<AnyAction>, getState: () => IStoreState) => {
    const tenantName = getState().tenants.selectedTenant;
    dispatch(new FetchQnAPendingAction());
    fetchQnAResponses(auth, requestParams, tenantName)
      .then((response: Api.IQnAResponses) => {
        dispatch(new FetchQnASuccessAction({ qnaResponses: response }));
      })
      .catch((errorResponse: Api.IQnAResponseErrorData) => {

        var dummyData: any = [];

        for (let i = 0; i < requestParams.pageSize; i++) {
          dummyData.push({
            id: "f2c5db77-301b-4007-a6d7-cca408ddb605",
            "title": i + "Verify identityidentityidentityidentity",
            "documentIds": ["3fa85f64-5717-4562-b3fc-2c963f66afa6"]
          });
        }

        dispatch(new FetchQnASuccessAction({
          qnaResponses: {
            data: dummyData,
            error: null,
            //metadata: null
            metadata: {
              "timestamp": "2024-09-26T10:47:40.4175552+00:00",
              "pageSize": 10,
              "currentPage": 1,
              "totalCount": 2497,
              "totalPages": 250
            }
          }
        }));


        //dispatch(new FetchQnAFailedAction({ error: errorResponse }));
      });
  }
}
