import { withOktaAuth } from "@okta/okta-react";
import * as React from "react";
import { connect } from "react-redux";
import { compose, Dispatch } from "redux";
import { validateAuthState as validateAuthState } from "../../Auth/AuthHelper";
import i18n from "../../i18n";
import { IStoreState } from "../../Model";
import { LoadingIndicator } from "../../Shared/LoadingIndicator";
import { SearchResponses } from "../components/SearchResponses/SearchResponses";
import { getLinkedResponsesThunk, InitialiseResponseSearhAction, getLinkedResponsesForExternalSourcesThunk, DefaultResponsesStateForResponsesAction, DefaultResponsesStateForExternalSourcesAction } from "../duck/Actions";
import './SearchResponses.scss';
import { Action as AnyAction } from "@reduxjs/toolkit";
import { AuthState } from "@okta/okta-auth-js";
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";
import appHistory from '../../AppHistory';
import * as qs from 'qs';

interface ISearchResponsePageStateProps {
  failed: boolean;
  fetched: boolean;
  fetching: boolean;
  error: string;
  isDocumentIdValid: boolean;
  responses: Api.IPagedResult<Api.ICompositeResponseViewModel>;
  isQueryValid: boolean;
  externalSourcesFailed: boolean;
  externalSourcesFetched: boolean;
  externalSourcesFetching: boolean;
  externalSourcesResponses: Api.IExternalSourcesSearchReducerResponse
}

interface ISearchResponsePageDispatchProps {
  getResponsesLinkedToDocument: (docId: number, pageNumber: number, pageSize: number, sortOrder: string, auth: AuthState | null) => any;
  initialiseState: () => any;
  getLinkedResponsesForExternalSources: (selectedTenant: string, query: string, pageNumber: number, pageSize: number, auth: AuthState | null) => any;
  DefaultResponsesStateForResponses: () => any;
  DefaultResponsesStateForExternalSources: () => any;
}

interface ISearchResponsePageOwnProps {
  docId: number,
  pageNumber: number,
  sortOrder: string,
  enableExternalDataSources: boolean,
  selectedTenant: string,
  query: string,
  externalSourcePageNumber: number,
}

type ISearchResponseProps = ISearchResponsePageStateProps & ISearchResponsePageDispatchProps & ISearchResponsePageOwnProps & WithOktaAuthProps;

class SearchResponsePage extends React.Component<ISearchResponseProps> {
  constructor(props: ISearchResponseProps) {
    super(props);

    if (this.props.docId) {
      this.props.getResponsesLinkedToDocument(this.props.docId, this.props.pageNumber, this.props.responses.pageSize, this.props.sortOrder || '', this.props.authState);
    }
  }

  public componentDidUpdate(prevProps: ISearchResponseProps) {
    if (this.props.authState?.isAuthenticated) {
      if (prevProps && this.props.docId) {
        if (prevProps.pageNumber !== this.props.pageNumber ||
          prevProps.sortOrder !== this.props.sortOrder ||
          prevProps.responses.pageSize !== this.props.responses.pageSize ||
          prevProps.responses.currentPage !== this.props.pageNumber) {
          this.props.getResponsesLinkedToDocument(this.props.docId, this.props.pageNumber, this.props.responses.pageSize, this.props.sortOrder || '', this.props.authState);
        }
      }

      if (prevProps && this.props.isQueryValid) {
        const searchParams = qs.parse(appHistory.location.search, { ignoreQueryPrefix: true });
        let query = searchParams.query;
        if (query == undefined) {
          query = "";
        }

        if (prevProps.externalSourcePageNumber !== this.props.externalSourcePageNumber ||
          prevProps.externalSourcesResponses.pageSize !== this.props.externalSourcesResponses.pageSize ||
          prevProps.externalSourcesResponses.currentPage !== this.props.externalSourcesResponses.currentPage) {
          this.props.getLinkedResponsesForExternalSources(this.props.selectedTenant, query.toString(), this.props.pageNumber, this.props.externalSourcesResponses.pageSize, this.props.authState);
        }
      }
    }
    else {
      validateAuthState(this.props.authState);
    }
  }

  public componentDidMount() {
    if (this.props.authState?.isAuthenticated) {
      this.props.initialiseState();
    }
    else {
      validateAuthState(this.props.authState);
    }
  }

  public render() {
    if (!this.props.authState) {
      return <LoadingIndicator busy={true} />
    }
    if (this.props.authState?.error) {
      throw this.props.authState.error;
    }

    const documentId = this.props.docId || 0;
    return (
      <div className="search-responses">
        <div className="page-title">
          <h1>{i18n.t("manage.pageTitleWhenEditing")}</h1>
        </div>

        <SearchResponses searched={this.props.fetched} isDocumentIdValid={this.props.isDocumentIdValid} error={this.props.error} failed={this.props.failed} fetching={this.props.fetching} documentId={documentId} responses={this.props.responses} getResponsesLinkedToDocument={this.props.getResponsesLinkedToDocument} authState={this.props.authState} getLinkedResponsesForExternalSources={this.props.getLinkedResponsesForExternalSources} isQueryValid={this.props.isQueryValid} externalSourcesSearched={this.props.externalSourcesFetched} externalSourcesFailed={this.props.externalSourcesFailed} externalSourcesFetching={this.props.externalSourcesFetching} enableExternalDataSources={this.props.enableExternalDataSources} externalSourcesResponses={this.props.externalSourcesResponses}
          DefaultResponsesStateForResponses={this.props.DefaultResponsesStateForResponses} DefaultResponsesStateForExternalSources={this.props.DefaultResponsesStateForExternalSources} selectedTenant={this.props.selectedTenant} />
      </div>
    )
  }
}

const mapStateToProps = (state: IStoreState) => {
  return {
    ...state.searchResponse
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    getResponsesLinkedToDocument: (docId: number, pageNumber: number, pageSize: number, sortOrder: string, auth: AuthState) => {
      return dispatch(getLinkedResponsesThunk(docId, pageNumber, pageSize, sortOrder, auth));
    },
    initialiseState: () => dispatch(new InitialiseResponseSearhAction()),
    getLinkedResponsesForExternalSources: (selectedTenant: string, query: string, pageNumber: number, pageSize: number, auth: AuthState) => {
      return dispatch(getLinkedResponsesForExternalSourcesThunk(selectedTenant, query, pageNumber, pageSize, auth));
    },
    DefaultResponsesStateForExternalSources: () => dispatch(new DefaultResponsesStateForExternalSourcesAction()),
    DefaultResponsesStateForResponses: () => dispatch(new DefaultResponsesStateForResponsesAction())
  };
};

const hoc = compose(withOktaAuth, connect<ISearchResponsePageStateProps, ISearchResponsePageDispatchProps, ISearchResponsePageOwnProps>(mapStateToProps, mapDispatchToProps))(SearchResponsePage);

export { hoc as SearchResponsePage };
