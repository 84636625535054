import * as classNames from 'classnames';
import * as qs from 'qs';
import * as React from "react";
import appHistory from "../../../AppHistory";
import { DateTimeFormatter } from "../../../Shared/Util/DateTimeFormatter";

interface IResponseListRow {
  response: Api.ICompositeResponseViewModel,
  isPanvivaCoreEnabled: boolean,
  externalSourcesResponse: Api.IExternalSourcesCompositeResponseViewModel;

}

class CompositeResponseListRow extends React.Component<IResponseListRow> {
  constructor(props: IResponseListRow) {
    super(props);

    this.handleRowClick = this.handleRowClick.bind(this);
  }

  public render() {
    const isDraft = this.props.response.status === 'Draft';
    const isExternalSourcesDraft = this.props.externalSourcesResponse.status === 'Draft';
    return (
      <>
        {
          this.props.isPanvivaCoreEnabled ?
            <tr onClick={this.handleRowClick(isDraft)}>
              <td>{this.props.response.title}</td>
              <td className="response-status">
                <h5>
                  <span className={classNames("badge", { "badge-secondary": isDraft, "badge-primary": !isDraft })}>
                    {this.props.response.status}
                  </span>
                </h5>

              </td>
              <td>{this.props.response.category}</td>
              {this.props.isPanvivaCoreEnabled ?
                <td>{DateTimeFormatter.ToShortDate(this.props.response.dateModified)}</td> : <td>{DateTimeFormatter.ToShortDate(this.props.externalSourcesResponse.dateModified)}</td>
              }
            </tr> :
            <tr onClick={this.handleRowClick(isExternalSourcesDraft)}>
              <td>{this.props.externalSourcesResponse.title}</td>
              <td className="response-status">
                <h5>
                  <span className={classNames("badge", { "badge-secondary": isExternalSourcesDraft, "badge-primary": !isExternalSourcesDraft })}>
                    {this.props.externalSourcesResponse.status}
                  </span>
                </h5>

              </td>
              <td>{this.props.externalSourcesResponse.category}</td>
              {this.props.isPanvivaCoreEnabled ?
                <td>{DateTimeFormatter.ToShortDate(this.props.externalSourcesResponse.dateModified)}</td> : <td>{DateTimeFormatter.ToShortDate(this.props.externalSourcesResponse.dateModified)}</td>
              }
            </tr>
        }
      </>
    )
  }

  private handleRowClick = (isDraft: boolean) => (e: any) => {
    if (this.props.isPanvivaCoreEnabled) {
      const searchParams = qs.parse(appHistory.location.search, { ignoreQueryPrefix: true });
      appHistory.push(`/manage/${this.props.response.id}?documentId=${searchParams.docId}&isDraft=${isDraft}`);
    } else {
      const searchParams = qs.parse(appHistory.location.search, { ignoreQueryPrefix: true });
      if (this.props.externalSourcesResponse.isExternalArtefact) {
        appHistory.push(`/manage/${this.props.externalSourcesResponse.id}?primaryQuery=${searchParams.query}&isExternal=true&isDraft=${isDraft}`);
      } else {
        appHistory.push(`/manage/${this.props.externalSourcesResponse.id}?primaryQuery=${searchParams.query}&documentId=${this.props.externalSourcesResponse.panvivaDocumentId}&isDraft=${isDraft}`);
      }
    }
  }
}

export { CompositeResponseListRow };
