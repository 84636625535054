import * as React from "react";
import appHistory from "../../AppHistory";
import { DateTimeFormatter } from "../../Shared/Util/DateTimeFormatter";

interface IResponseListRow {
  response: Api.IResponseListViewModel,
  isDraft?: boolean;
}

class ResponseListRow extends React.Component<IResponseListRow> {
  constructor(props: IResponseListRow) {
    super(props);

    this.handleRowClick = this.handleRowClick.bind(this);
  }

  public render() {
    return (
      <tr onClick={this.handleRowClick}>
        <td>{this.props.response.referenceId}</td>
        <td className='title-link'>{this.props.response.title}</td>
        <td>{this.props.response.category}</td>
        <td>{DateTimeFormatter.ToShortDate(this.props.response.dateModified)}</td>
      </tr>
    )
  }

  private handleRowClick(e: any) {
    let url = this.props.response.isExternalArtefact
      ? `/manage/${this.props.response.id}?isExternal=true`
      : `/manage/${this.props.response.id}?documentId=${this.props.response.documentId}`;

    if(!!this.props.isDraft) {
      url = `${url}&isDraft=true`;
    }
    appHistory.push(url);
  }
}

export { ResponseListRow };
